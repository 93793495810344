/*
 * Jqx TreeGrid
 */
[dir] .jqx-grid-cell {
  background: #343d55 !important;
}

[dir] .jqx-fill-state-pressed {
  border-color: #999 !important;
  background: #161d31 !important;
}

[dir] .jqx-widget .jqx-grid-cell,
[dir] .jqx-widget .jqx-grid-column-header,
[dir] .jqx-widget .jqx-grid-group-cell {
  border-color: #202642 !important;
}

[dir] .jqx-widget-content {
  border-color: #161d31 !important;
  background: #161d31 !important;
}
[dir] .jqx-widget-header {
  border-color: #161d31 !important;
  background-color: #161d31 !important;
}

[dir] .jqx-grid {
  border-width: 5px 15px !important;
}

.jqx-widget {
  color: #c2c6dc !important;
}

[dir] .jqx-grid-table {
  border-right: 0px solid #aaa !important;
}

[dir] .react-toggle--checked .react-toggle-track {
  background-color: #7367f0 !important;
}

[dir] .react-toggle-track {
  width: 50px !important;
  background-color: #283046 !important;
}

/*
 * Toggle input 
 */
.toggle-col {
  display: flex;
  flex-direction: column;
  .toggle-btn {
    display: flex;
    align-items: center;
  }
}

/*
 * Table Cell for game page
 */
.list-view .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  height: 100px !important;
}

/*
 * Table Cell for game page
 */
.mr-5px {
  margin-right: 5px;
}

.tbl-lang-image {
  // flag image width in language cms
  width: 20px;
  margin-right: 5px;
}

.jusity-right {
  justify-content: right;
}

iframe {
  z-index: -1 !important;
}

.kyc-file {
  width: 30px;
}

::-webkit-scrollbar {
  @media (max-width: 2500px) {
    width: 12px;
  }
  @media (max-width: 1500px) {
    width: 12px !important;
  }
  @media (max-width: 800px) {
    width: 7px !important;
  }
  height: 7px;
  background: #eaeaea !important;
}
::-webkit-scrollbar-thumb {
  background: #a5a5a5 !important;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #8a8989 !important;
}

.dash-dt {
  width: 100% !important;
}

.revenubuttonactive {
  background: rgba(0, 207, 232, 0.2);
}

.pr-0 {
  padding-right: 0;
}

.text-truncate {
  overflow: hidden;
  text-overflow: unset;
  white-space: normal !important;
}

.gateway-type {
  margin: 0 0 0 2rem;
}

.react-json-view {
  span {
    color: white !important;
  }
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  height: auto !important;
}

.status_data {
  font-size: 1.2rem;
  margin: 0;
}
.status_file_name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  width: 100%;
}
.flex-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.config-line {
  border-bottom: 1px solid gray;
  margin-bottom: 1rem;
}
.terms-file {
  display: flex;
  align-items: center;
  text-decoration: underline;
  user-select: none;
  cursor: pointer;
}
.react-code-input {
  input {
    outline: unset !important;
  }
}

.multi-select {
  --rmsc-main: #3b4253 !important;
  --rmsc-hover: #283046 !important;
  --rmsc-selected: #283046 !important;
  --rmsc-border: #3b4253 !important;
  --rmsc-gray: #ccc !important;
  --rmsc-bg: #283046 !important;
  --rmsc-p: 10px !important;
  --rmsc-radius: 4px !important;
  --rmsc-h: 38px !important;
  input {
    background-color: #283046 !important;
    color: white !important;
  }
}

.overflowx-scroll{
  overflow-x: auto;
}
.margin-right-10{
  margin-right: 10px;
}

.phone-number-input:focus{
  border-color: #7367f0 !important;
}
.phone-number-button{
  // background-color: #283046 !important;
  // border-color: #404656 !important;
  border: transparent !important;
  padding: 2px !important;
  background: transparent !important;
}
.phone-number-button .selected-flag{
  border: transparent !important;
  background-color: #283046 !important;
}
.phone-number-dropdown{
  background-color: #283046 !important;
}
.phone-number-dropdown .highlight{
  background-color: #7367f0 !important;
}
.phone-number-dropdown li:hover{
  color: #625bc9 !important;
  background-color: #31375a !important;
}